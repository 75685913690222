import React, {useState, useRef} from 'react';
import {CircularProgress} from "@chakra-ui/progress";
import axios from "axios";
import "./styles.css";

const WorkSpace = () => {
    const [query, setQuery] = useState('');
    const [progress, setProgress] = useState(true)
    const [result, setResult] = useState(null)
    const errRef = useRef();

    const [errMsg, setErrMsg] = useState('');

    const sendQuery = () =>{
        if (query){
            setProgress(false)
            axios.post("https://saas-server-u5lepj2v4q-uc.a.run.app/api/query", {"query": query})
            .then((res) => {
                setProgress(true)
                setErrMsg('')
                setResult(res.data)
            })
            .catch((err) => {
                if (!err?.response) {
                    setProgress(true)
                    setResult(null)
                    setErrMsg('No Server Response');
                }
                else{
                    setProgress(true)
                    setResult(null)
                    setErrMsg('Are you sure?');
                }
            })
        }
    }

    return (
        <div className="workspace">
            <br/>
            <br/>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <div className="container">
                <div className='progress'>
                    {
                        progress ? <div className='queryBox'>
                                                <div className='query'>{query}</div>
                                                <div className='queryResponse'>{result}</div>
                                            </div> :
                            <div className='pProgress'>
                                I know it will take a long time. Please wait patiently...
                                <CircularProgress className='progressCirc' size='2rem' isIndeterminate />
                            </div>
                             
                    }
                </div>
            </div>
            <div className="container_box_elementx">
                <input id="clearInput" className="inputCardx white-glassmorphismx" type="text"
                    placeholder="Query"
                    autoComplete="off"
                    /*onKeyDown={(e) => {
                        if (e.key === "Enter")
                            sendQuery();
                        }}
                    */
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    disabled={!progress}
                />
                {
                    progress && <div className="container_box_buttonx">
                                    <button className='buttonSend' onClick={sendQuery} >I feel myself lucky</button>
                                    <a className='backsend' href="/Home">Back</a>
                                 </div>
                }
            </div>
            
        </div>
    )

}

export default WorkSpace;