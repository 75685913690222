import React, {useState, useRef, useEffect} from 'react'
import {CircularProgress} from "@chakra-ui/progress"
import FileBase from 'react-file-base64';
import ast from "../utils/ast.jpg"
import axios  from 'axios';
import astbg from "../utils/astbg.png"
import {saveAs} from "file-saver";
import { BiCloudDownload } from "react-icons/bi";
import "./styles.css"


const Home = () => {
    const [selectedFile, setSelectedFile] = useState('');
    const [imageData, setImageData] = useState()
    const [result, setResult] = useState(null)
    const [progress, setProgress] = useState(null)
    const errRef = useRef();

    const [errMsg, setErrMsg] = useState('');
    

    const stateActionButton = () => {
        if (selectedFile.base64 == null){
            return true
        }
        else {
            if (progress){
                return progress
            }
        }
    }


    const download_img = () => {
        let url = `data:image/jpeg;base64,${result}`
        saveAs(url, "image.png");
    }

    const handleSubmit = async (e) => {

        setProgress(true)
        e.preventDefault();
        if (imageData){
            axios.post("https://saas-server-u5lepj2v4q-uc.a.run.app/api/image", imageData)
            .then((res) => {
                setProgress(false)
                setErrMsg('')
                setResult(res.data)
            })
            .catch((err) => {
                if (!err?.response) {
                    setProgress(false)
                    setErrMsg('No Server Response');
                }
                else{
                    setProgress(false)
                    setErrMsg('Are you sure the file is a photo?');
                }
            })
        }
    };

    const getImage = () => {
        if(selectedFile.base64){
            setImageData(selectedFile.base64.split(',')[1])
        }
    }

    useEffect(() => {
        getImage()
    },[selectedFile.base64])


  return (
    <div className='home_remove'>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        <div className='container_remove'>
            <div className='container_box_remove'>
                <div className='container_box_element_remove'>
                   {
                    selectedFile.base64 ? (<img src={selectedFile.base64} alt="" />) : (<img src={ast} alt="" />)
                   } 
                </div>
                <div className='container_box_button_remove'>
                    <button className='input_box_remove' >
                        <FileBase type="file" multiple={false} onDone={(base64) => setSelectedFile(base64)} />
                    </button>
                </div>
            </div>
            <div className='container_box_remove'>
                <div className='container_box_element_remove'>
                {
                    progress ? (<CircularProgress size='100px' isIndeterminate />)
                    : (result ? (<img src={`data:image/jpeg;base64,${result}`} />) : (<img src={astbg} alt="" />))
                }
                </div>
                <div className='container_box_button_remove'>
                    <button disabled={stateActionButton()} className='button_box_remove' onClick={handleSubmit}> Action </button>
                    {
                        result &&  <button className='button_box_remove' onClick={download_img}>
                                        <BiCloudDownload className='icon_download_remove'/>
                                   </button>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home
