import React from 'react'
import "./styles.css"

const Welcome = () => {
  return (
    <div className='welcome'>
        <a href='/Home' className="buttonSend_welcome">RAG</a>
        <a href='/Remove' className="buttonSend_welcome">Photo Edit</a>
    </div>
  )
}

export default Welcome