import React, {useState, useRef, useEffect} from 'react';
import {Navigate} from "react-router-dom"
import {CircularProgress} from "@chakra-ui/progress";
import FileBase from 'react-file-base64';
import axios  from 'axios';
import "./styles.css";

const Home = () => {

      const [file, setfile] = useState(null);
      const [selectedFile, setSelectedFile] = useState('');
      const [result, setResult] = useState(null)
      const [progress, setProgress] = useState(null)
      const errRef = useRef();

      const [errMsg, setErrMsg] = useState('');

      const handleSubmit = async () => {
        setProgress(true)

        if (file){
            axios.post("https://saas-server-u5lepj2v4q-uc.a.run.app/api/document", file)
            .then((res) => {
                if (res.data === "We are ready!"){
                    setErrMsg('')
                    setProgress(false)
                    setResult(res.data)
                }
               
            })
            .catch((err) => {
                if (!err?.response) {
                    setProgress(false)
                    setErrMsg('No Server Response');
                }
                else{
                    setProgress(false)
                    setErrMsg('Are you sure?');
                }
            })
        }

    };
      
    const getFile = () => {
        if(selectedFile.base64){
            setfile(selectedFile.base64.split(',')[1])
        }
    }

    useEffect(() => {
        getFile()
    },[selectedFile.base64])


    return(
        <div className='home'>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <form onSubmit={handleSubmit} className="formClass">
                {file ? (progress ? <CircularProgress size='100px' isIndeterminate /> :
                    <div>
                        <p className="inputCard white-glassmorphism" >{ selectedFile.name}</p>
                        <p className="inputCard white-glassmorphism" >{ selectedFile.type}</p>
                        <p className="inputCard white-glassmorphism" >{ selectedFile.size}</p>
                    </div>
                )  : 
                (
                    <div>
                        <p className="inputCard white-glassmorphism" />
                        <p className="inputCard white-glassmorphism" />
                        <p className="inputCard white-glassmorphism" />
                    </div>
                )
                }
                <div className='container_box_button'>
                    <button className='input_box' >
                        <FileBase type="file" multiple={false} onDone={(base64) => setSelectedFile(base64)} />
                    </button>
                </div>
                <button type="button" onClick={handleSubmit} className="buttonSend">Upload</button>
            </form>

            {
                result && <Navigate to="/WorkSpace" replace={true} />
            }

        </div>
    );
}

export default Home;