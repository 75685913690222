import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {Home, WorkSpace, Remove, Welcome} from './components';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<Welcome />} />
          <Route path='/Welcome' element={<Welcome />} />
          <Route path='/Home' element={<Home />} />
          <Route path='/WorkSpace' element={<WorkSpace />} />
          <Route path='/Remove' element={<Remove />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
